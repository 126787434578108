import React from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GetInTouch() {
	const [subject, setSubject] = useState('');
	const [number, setNumber] = useState('');
	const [body, setBody] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [isSubscribed, setIsSubscribed] = useState(true);
	const [getDemo, setGetDemo] = useState(true);

	const sendMail = async () => {
		if (!email || !number || !body || !subject || !firstName || !lastName) {
			return toast.error('Please complete all fields before proceeding', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		}
		const templateParams = {
			name: firstName + ' ' + lastName,
			subject,
			number,
			body: `Name: ${firstName + ' ' + lastName},  ${
				isSubscribed ? ' Newsletter Subscribed' : 'Newsletter Not Subscribed'
			}, ${getDemo ? 'Demo Required' : 'Demo Not Required'}, ${body}`,
			email,
		};
		await emailjs
			.send(
				'service_hvnje3h',
				'template_zbxasgp',
				templateParams,
				'tGyeYe6CvxT-65_Wn'
			)
			.then(
				(response) => {
					console.log('SUCCESS!', response.status, response.text);
					setSubject('');
					setFirstName('');
					setLastName('');
					setNumber('');
					setEmail('');
					setBody('');
					setIsSubscribed(true);
					setGetDemo(true);
					toast.success('thanks for submitting form', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					});
				},
				(err) => {
					console.log('FAILED...', err);
				}
			);
	};

	return (
		<div className='flex flex-col justify-center items-center align-center sm:mx-28 sm:rounded-2xl mt-[100px] sm:mt-60 bg-[#00208C] sm:py-[60px] px-4 py-4 sm:px-0'>
			<p className='text-white font-bold text-2xl sm:text-4xl' id='#submitForm'>
				Get in touch
			</p>
			<p className='text-white font-medium text-base mt-4 text-center'>
				For Product Demo and other queries please enter your details below.
			</p>
			<div className='flex flex-col mt-[48px] sm:w-[800px] sm:space-y-[70px] '>
				<div className=' flex flex-col sm:flex-row justify-between space-y-3 sm:space-y-0'>
					<div className='flex flex-col space-y-1 '>
						<p className='text-white text-base sm:text-lg font-normal'>
							First Name
						</p>
						<input
							className='rounded-[4px] mt-[4px] h-[40px] w-[360px] px-4'
							type='text'
							placeholder='First Name'
							onChange={(e) => setFirstName(e.target.value)}
							value={firstName}
							required
						/>
					</div>
					<div className='flex flex-col space-y-1'>
						<p className='text-white text-lg font-normal'>Last Name</p>
						<input
							className='rounded-[4px] mt-[4px] h-[40px] w-[360px] px-4'
							type='text'
							placeholder='Last Name'
							onChange={(e) => setLastName(e.target.value)}
							value={lastName}
						/>
					</div>
				</div>
			</div>

			<div className='flex flex-col  mt-[48px] sm:w-[800px] sm:space-y-[70px]'>
				<div className='flex flex-col sm:flex-row justify-between space-y-3 sm:space-y-0'>
					<div className='flex flex-col space-y-1'>
						<p className='text-white text-lg font-normal'>Email</p>
						<input
							className='rounded-[4px] mt-[4px] h-[40px] w-[360px] px-4'
							type='text'
							placeholder='Email....'
							onChange={(e) => setEmail(e.target.value)}
							value={email}
						/>
					</div>
					<div className='flex flex-col'>
						<p className='text-white text-lg font-normal'>
							Phone Number (add country code)
						</p>
						<input
							className='rounded-[4px] mt-[4px] h-[40px] w-[360px] px-4'
							type='text'
							placeholder='Type Number'
							onChange={(e) => setNumber(e.target.value)}
							value={number}
						/>
					</div>
				</div>
			</div>

			<div className='flex flex-col mt-[48px] sm:w-[800px] '>
				<div className='flex space-y-1'>
					<div className='flex flex-col sm:w-full w-[360px]'>
						<p className='text-white text-lg font-normal'>Title / SubTitle</p>
						<input
							className='rounded-[4px] mt-[4px] h-[40px] w-full px-4'
							type='text'
							placeholder='Subject'
							onChange={(e) => setSubject(e.target.value)}
							value={subject}
						/>
					</div>
				</div>
			</div>

			<div className='flex flex-col mt-[48px] sm:w-[800px] '>
				<div className='flex space-y-1'>
					<div className='flex flex-col sm:w-full w-[360px]'>
						<p className='text-white text-lg font-normal'>
							Write your message here
						</p>
						<textarea
							className='rounded-[4px] mt-[4px] h-[140px] w-full p-4'
							type='text'
							placeholder='type here...'
							onChange={(e) => setBody(e.target.value)}
							value={body}
						/>
					</div>
				</div>
			</div>

			<div className='flex sm:flex-row flex-col space-y-4 sm:space-y-0 sm:space-x-10 mt-8'>
				{/* Toggle 1 */}
				<div className='flex items-center space-x-4'>
					<input
						type='checkbox'
						id='subscribe'
						className='w-6 h-6 text-green-500 border-2 border-gray-300 rounded-lg focus:ring-green-500 focus:ring-opacity-50'
						checked={isSubscribed}
						onChange={() => setIsSubscribed(!isSubscribed)}
					/>
					<label
						htmlFor='subscribe'
						className='text-white font-medium cursor-pointer'
					>
						Subscribe to Newsletter
					</label>
				</div>

				{/* Toggle 2 */}
				<div className='flex items-center space-x-4'>
					<input
						type='checkbox'
						id='getDemo'
						className='w-6 h-6 text-green-500 border-2 border-gray-300  focus:ring-green-500 focus:ring-opacity-50 rounded-lg'
						checked={getDemo}
						onChange={() => setGetDemo(!getDemo)}
					/>
					<label
						htmlFor='getDemo'
						className='text-white font-medium cursor-pointer'
					>
						Get a Demo
					</label>
				</div>
			</div>

			<div
				className='bg-[#009900] px-8 py-2 rounded-3xl mt-[48px] cursor-pointer'
				onClick={sendMail}
			>
				<p className='text-white'>Submit</p>
			</div>
			<ToastContainer />
		</div>
	);
}

export default GetInTouch;
